import * as React from "react"
import { Link } from "gatsby"
import Sidebar from "./sidebar"
import Search from "./search"
import { MenuCategory } from "../types/menu-category"

interface HeaderProps {
  selectedCategory: MenuCategory
  path: string
  apiDocumentationLink: string
  algoliaDocsPagesIndexName: string
  allTopLevelCategories: string[]
  onNavigationChange: (selectedTab: string) => void
}

interface HeaderState {
  isMenuVisible: boolean
}

class Header extends React.Component<HeaderProps, HeaderState> {
  constructor(props: HeaderProps) {
    super(props)
    this.state = {
      isMenuVisible: false,
    }
  }

  toggleMenu(isMenuVisible: boolean) {
    this.setState({
      isMenuVisible: !isMenuVisible,
    })
  }

  setActiveTabInMenu(selectedTab: string) {
    this.props.onNavigationChange(selectedTab)
  }

  render() {
    const { isMenuVisible } = this.state
    const activeTabInMenu = this.props.selectedCategory.category || ""

    const {
      selectedCategory,
      path,
      allTopLevelCategories,
      apiDocumentationLink,
      algoliaDocsPagesIndexName,
    } = this.props
    const searchIndices: SearchIndex[] = [
      {
        name: algoliaDocsPagesIndexName,
      },
    ]

    return (
      <header>
        <nav
          className="navbar is-flex is-flex-direction-row is-flex-wrap-wrap"
          role="navigation"
          aria-label="main navigation"
        >
          <div style={{ flexGrow: 1 }}>
            <div className="navbar-brand is-flex is-flex-direction-row is-flex-wrap-wrap">
              <Link
                className="navbar-item"
                onClick={() => {
                  this.setActiveTabInMenu(allTopLevelCategories[0])
                  this.toggleMenu(true)
                }}
                to="/"
              >
                <span className="receeve-logo-container">
                  <span className="receeve-logo"></span>
                </span>
                <span className="docs has-text-white">DEVS</span>
              </Link>

              <div className="navbar-start column is-4-fullhd is-4-desktop is-8-tablet is-12-mobile p-0">
                <div className="navbar-item">
                  <div className="field is-full-width">
                    <div className="control has-icons-left has-icons-right">
                      <Search indices={searchIndices} />
                    </div>
                  </div>
                </div>
              </div>

              <a
                className={`navbar-burger burger has-text-white ${
                  isMenuVisible ? "is-active" : ""
                }`}
                onClick={() => {
                  this.toggleMenu(isMenuVisible)
                }}
                role="button"
                aria-label="menu"
                aria-expanded="false"
                data-target="navMenu"
              >
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
              </a>
            </div>

            <div id="navMenu" className={`navbar-menu ${isMenuVisible ? "is-active" : ""}`}>
              <div className="navbar-start">
                {allTopLevelCategories.map(x => (
                  <div key={`div-${x}`}>
                    <a
                      onClick={() => {
                        this.setActiveTabInMenu(x)
                        if (x.toLowerCase() === allTopLevelCategories[0].toLocaleLowerCase())
                          this.toggleMenu(isMenuVisible)
                      }}
                      className={`navbar-item is-tab has-text-white ${
                        x.toLocaleLowerCase() === activeTabInMenu.toLocaleLowerCase()
                          ? "is-active"
                          : ""
                      }`}
                      key={x}
                    >
                      {x}
                    </a>
                    {x === selectedCategory.category && (
                      <div className="is-hidden-fullhd is-hidden-desktop subcategories-container">
                        <Sidebar
                          keyPrefix="header-"
                          onPageSelected={() => {
                            this.toggleMenu(isMenuVisible)
                          }}
                          currentPagePath={path}
                          category={selectedCategory}
                        />
                      </div>
                    )}
                  </div>
                ))}
              </div>

              <div className="navbar-end">
                <div className="navbar-item">
                  <a
                    className="api-link"
                    href={apiDocumentationLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <strong>API Reference</strong>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
    )
  }
}

export default Header
