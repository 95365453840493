module.exports.getCategoryFromPagePath = (pagePath, categoriesTree) => {
  for (let i = 0; i < categoriesTree.length; i++) {
    const pages = categoriesTree[i].pages
    if (pages?.filter(page => page.path === pagePath).length > 0) {
      return categoriesTree[i]
    }

    const subcategories = categoriesTree[i].subcategories
    for (let j = 0; j < subcategories?.length; j++) {
      const subcategoryPages = subcategories[j].pages
      if (subcategoryPages?.filter(page => page.path === pagePath).length > 0) {
        return categoriesTree[i]
      }
    }
  }

  return null
}

module.exports.getTopLevelCategories = categoriesTree => {
  return categoriesTree.map(x => x.category)
}

module.exports.getCategoryByName = (categoryName, categoriesTree) => {
  const filteredCategories = categoriesTree.filter(x => x.category === categoryName)
  if (filteredCategories.length > 0) return filteredCategories[0]

  throw new Error(`No category found with name: ${categoryName}`)
}
