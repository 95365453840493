import React from "react"
import { Link } from "gatsby"
import { MenuCategory } from "../types/menu-category"

interface SidebarProps {
  category: MenuCategory
  currentPagePath: string
  keyPrefix?: string
  onPageSelected?: () => void
}

const Sidebar = (props: SidebarProps) => {
  const { category, currentPagePath, onPageSelected, keyPrefix } = props
  const subcategories: string[] = []
  category?.subcategories?.forEach(item => {
    if (item.category && subcategories.indexOf(item.category) < 0) {
      subcategories.push(item.category)
    }
  })

  return (
    <div>
      <aside className="menu">
        {category?.pages &&
          category?.pages.map(page => (
            <ul className="menu-list" key={`${keyPrefix || ""}${page?.path}`}>
              <li key={`${keyPrefix || ""}${page?.path}`}>
                <Link
                  className={` ${
                    page.path === currentPagePath ? "is-active" : ""
                  }`}
                  onClick={() => {
                    if (onPageSelected) onPageSelected()
                  }}
                  to={page.path}
                >
                  {page.title}
                </Link>
              </li>
            </ul>
          ))}

        {subcategories.map(x => (
          <React.Fragment key={`${keyPrefix || ""}${x}`}>
            <p className="menu-label">{x}</p>
            <ul className="menu-list">
              {category?.subcategories
                ?.filter(y => y.category === x)[0]
                ?.pages.map(page => (
                  <li key={`${keyPrefix || ""}${page.path}`}>
                    <Link
                      className={` ${
                        page.path === currentPagePath ? "is-active" : ""
                      }`}
                      onClick={() => {
                        if (onPageSelected) onPageSelected()
                      }}
                      to={page.path}
                    >
                      {page.title}
                    </Link>
                  </li>
                ))}
            </ul>
          </React.Fragment>
        ))}
      </aside>
    </div>
  )
}

export default Sidebar
