/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
require("./src/styles/typography.css")

// Called when user navigates from one page to another
exports.shouldUpdateScroll = ({ prevRouterProps, routerProps }) => {
  // if we are not coming from a previous page, fallback to default behavior (if there is no hash value)
  // if there is hash value, then scroll to the element pointed to by hash value
  if (!prevRouterProps) {
    if (routerProps?.location?.hash) {
      const targetElement = document.getElementById(routerProps?.location?.hash.substring(1))
      if (targetElement) {
        targetElement.scrollIntoView()
      }

      return false
    }

    return true
  }

  // If route is same, and only hash has changed
  if (
    prevRouterProps?.location?.pathname === routerProps?.location?.pathname &&
    prevRouterProps?.location?.hash !== routerProps?.location?.hash
  ) {
    return false
  }

  // Scroll to top when user navigates to another page
  const contentContainer = document.getElementsByClassName("content-area")
  if (contentContainer && contentContainer[0]) {
    contentContainer[0].scrollTo(0, 0)
  }

  // for small screens, where we do not have sticky header
  window?.scrollTo(0, 0)

  return false
}
