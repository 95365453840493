import React from "react"
import { connectSearchBox } from "react-instantsearch-dom"

export default connectSearchBox(({ refine, currentRefinement, className, onFocus }: any) => (
  <div className={className}>
    <input
      className="SearchInput input is-primary"
      type="text"
      placeholder="Search documentation..."
      aria-label="Search"
      onChange={e => refine(e.target.value)}
      value={currentRefinement}
      onFocus={onFocus}
    />
    <span className="icon is-small is-left">
      <span className="material-icons">search</span>
    </span>
  </div>
))
