import * as React from "react"

interface FooterProps {
  supportLink: string
  privacyPolicyLink: string
}
const Footer = (props: FooterProps) => {
  const { supportLink, privacyPolicyLink } = props

  return (
    <footer className="footer">
      <hr />
      <div className="content has-text-centered">
        <div>
          <div className="columns has-text-left">
            <div className="column is-6">
              <p className="" style={{ marginTop: "-5px" }}>
                <span
                  className="material-icons-outlined"
                  style={{
                    top: "5px",
                    position: "relative",
                    fontSize: "20px",
                    marginRight: "5px",
                  }}
                >
                  contact_support
                </span>

                <strong>
                  Questions?{" "}
                  <a
                    href="#"
                    onClick={(e: any) => {
                      e.target.href = supportLink
                    }}
                  >
                    Contact us.{" "}
                  </a>
                </strong>
                <p className="mt-2 is-size-7 is-hidden-fullhd is-hidden-desktop is-hidden-tablet">
                  {/* You can unsubscribe at any time.  */}
                  Read our <a href={privacyPolicyLink}>privacy policy</a>
                </p>
              </p>
            </div>
            <div className="column is-6 has-text-right is-hidden-mobile">
              <p className="mt-2 is-size-7">
                {/* You can unsubscribe at any time.  */}
                Read our <a href={privacyPolicyLink}>privacy policy</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
