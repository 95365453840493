import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Helmet from "react-helmet"
import { navigate } from "gatsby"
import Seo from "./seo"
import Header from "./header"
import Footer from "./footer"
import Sidebar from "./sidebar"
import "./layout.css"
import "../styles/developer-docs.scss"
import { getCategoryByName, getTopLevelCategories } from "../utils/pagePathToCategoryMapper"
import { MarkdownRemark } from "../types/markdown-page-props"
import { SiteMetadata } from "../types/site-metadata"
import { MenuCategory } from "../types/menu-category"

interface LayoutProps {
  data: {
    markdownRemark: MarkdownRemark
    site: {
      siteMetadata: SiteMetadata
    }
  }
  pageContext: {
    data: {
      path: string
    }
    categoriesTree: MenuCategory[]
    category: MenuCategory
    hideSidebar?: boolean
    supportLink: string
    privacyPolicyLink: string
    apiDocumentationLink: string
    algoliaDocsPagesIndexName: string
  }
  children: React.ReactNode
}

const Layout = (props: LayoutProps) => {
  const { children, data, pageContext } = props
  const queryResult = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      allNavigationYaml {
        nodes {
          category
          pages {
            title
            path
          }
          subcategories {
            category
            pages {
              title
              path
            }
          }
        }
      }
    }
  `)

  const frontmatter = data?.markdownRemark?.frontmatter

  const siteMetadata: SiteMetadata = queryResult.site.siteMetadata
  const pageTitle = frontmatter?.title || siteMetadata.title || ""
  const {
    apiDocumentationLink,
    supportLink,
    privacyPolicyLink,
    algoliaDocsPagesIndexName,
  } = pageContext

  const currentPagePath = frontmatter?.path || pageContext.data?.path
  const isHomePage = currentPagePath === "/" || currentPagePath === ""
  const hideSidebar = pageContext.hideSidebar || isHomePage

  const categoriesTree = pageContext.categoriesTree || queryResult?.allNavigationYaml?.nodes
  const allTopLevelCategories = getTopLevelCategories(categoriesTree)
  const __selectedCategory = pageContext.category || (isHomePage && categoriesTree[0])
  
  const [, setselectedCategory] = useState(__selectedCategory)

  const handleNavigationChange = (categoryName: string) => {
    const category = getCategoryByName(categoryName, categoriesTree)
    setselectedCategory(category)

    const urlToNavigate =
      category?.pages && category?.pages[0]?.path ? category?.pages[0]?.path : "/"
    navigate(urlToNavigate)
  }

  return (
    <div>
      <Helmet>
        <title>{pageTitle.toString()}</title>
      </Helmet>

      <Seo title={pageTitle.toString()} />

      <Header
        apiDocumentationLink={apiDocumentationLink}
        algoliaDocsPagesIndexName={algoliaDocsPagesIndexName}
        path={currentPagePath}
        selectedCategory={__selectedCategory}
        allTopLevelCategories={allTopLevelCategories}
        onNavigationChange={handleNavigationChange}
      />

      <div className="main-container">
        <div className="columns">
          {hideSidebar && (
            <div className="column is-12-fullhd is-12-desktop is-12-tablet is-12-mobile p-0">
              <div className="columns is-centered content-area">
                <div className="column is-9-fullhd is-9-desktop is-12-tablet is-12-mobile">
                  <div className="content">
                    {children}
                    <Footer supportLink={supportLink} privacyPolicyLink={privacyPolicyLink} />
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* 
               Need to use .is-hidden instead of conditional rendering
               Somehow conditional rendering breaks layout in deployed version
               although it works well locally using both gatsby develop and [gatsby build, gatsby serve]
          */}
          {!hideSidebar && (
            <React.Fragment>
              <div
                className={`left-sidebar column is-2-fullhd is-2-desktop is-4-tablet is-hidden-mobile is-hidden-tablet-only ${
                  hideSidebar ? "is-hidden" : ""
                }`}
              >
                {/* Need to wrapp <Sidebar /> in an empty div to have padding on the bottom */}
                <div>
                  <Sidebar currentPagePath={currentPagePath} category={__selectedCategory} />
                </div>
              </div>
              <div className="column is-10-fullhd is-10-desktop is-12-tablet is-12-mobile p-0">
                <div className="columns is-centered content-area">{children}</div>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  )
}

export default Layout
