import styled, { css } from "styled-components"
import SearchResult from "./search-result"

const Popover = css`
  font-size: 14px;
  max-height: 60vh;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  z-index: 2;
  right: 0;
  top: 100%;
  margin-top: 0.5em;
  box-shadow: 0 0 5px 0;
  padding: 10px;
  border-radius: 2px;
  background: white;
`

export default styled(SearchResult)`
  display: ${(props: any) => (props.show ? `block` : `none`)};
  ${Popover}

  .HitCount {
    display: flex;
    margin-left: 10px;
    margin-bottom: 10px;
    font-weight: 600;
    color: #4a4a4a;
  }

  .Hits {
    ul {
      list-style: none;
      margin-left: 0;
    }

    li.ais-Hits-item {
      a {
        display: block;
        padding: 10px;

        h4 {
          font-weight: 600;
          margin-bottom: 10px;
          color: #485fc7 !important;
        }

        .ais-Snippet {
          color: black !important;
        }

        .search-result-path {
          margin-top: 5px;
          color: #828282 !important;
          font-weight: 600;
        }

        .search-result-path-separator {
          font-size: 12px;
          padding: 0 5px;
        }
      }

      a:hover {
        outline: none !important;
        background-color: #f0f0f0 !important;
        border-radius: 5px;
      }
    }

    li.ais-Hits-item:focus,
    li.ais-Hits-item:hover {
      background-color: #f0f0f0 !important;
      border-radius: 5px;
    }
  }

  .ais-PoweredBy {
    display: flex;
    justify-content: flex-end;
    font-size: 80%;

    .ais-PoweredBy-text {
      padding: 5px;
    }

    svg {
      width: 70px;
    }
  }
`
