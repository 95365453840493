import { default as React } from "react"
import { connectStateResults, Highlight, Hits, Index, Snippet } from "react-instantsearch-dom"

const HitCount = connectStateResults(({ searchResults }: any) => {
  const hitCount = searchResults && searchResults.nbHits

  return (
    <div className="HitCount">
      {hitCount === 0 ? "No " : hitCount} result{hitCount !== 1 ? `s` : ``}
    </div>
  )
})

const PageHit = ({ hit }: any) => {
  const pathSegments = hit.path?.split("/")

  return (
    <div>
      <a href={hit.path}>
        <h4>
          <Highlight attribute="title" hit={hit} tagName="mark" />
        </h4>
        <Snippet attribute="excerpt" hit={hit} tagName="mark" />
        <div className="search-result-path">
          {pathSegments.map((x: any, index: any) => {
            return (
              <span key={`page-hit-${index}`}>
                <span>{x}</span>
                {index > 0 && index + 1 < pathSegments.length && (
                  <span className="search-result-path-separator">{">"}</span>
                )}
              </span>
            )
          })}
        </div>
      </a>
    </div>
  )
}

const HitsInIndex = ({ index }: any) => {
  return (
    <Index indexName={index.name}>
      <HitCount />
      <Hits className="Hits" hitComponent={PageHit} />
    </Index>
  )
}

const SearchResult = connectStateResults(props => {
  const { indices, className }: any = props

  return (
    <div className={`${className} is-full-width`}>
      {indices.map((index: any) => (
        <HitsInIndex index={index} key={index.name} />
      ))}
    </div>
  )
})

export default SearchResult
